<template>
  <div>
    <v-container>
      <h1 class="pb-6">Sell/Trade</h1>

      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab>Buy</v-tab>
            <v-tab>Sell/Trade</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-row class="pt-6">
                  <v-col cols="12">
                    <h4>Keyword or Listing ID</h4>
                    <v-text-field outlined></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <h4>Livestock Type</h4>
                    <v-select outlined></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Breed</h4>
                    <v-select outlined></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Ad Type</h4>
                    <v-select outlined></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Location</h4>
                    <v-text-field outlined></v-text-field>
                  </v-col>
                  <v-col class="d-flex justify-center pb-12" cols="12">
                    <v-btn depressed color="primary">Search</v-btn>
                  </v-col>
                  <v-col class="d-flex align-center" cols="6">
                    <h4 class="pb-2">1 Active Listing Found</h4>
                  </v-col>
                  <v-col cols="6">
                    <h4>Sort By</h4>
                    <v-select outlined></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-card outlined>
                      <v-container class="d-flex">
                        <div>
                          <v-img
                            class="mr-6"
                            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.ixxYpKBjswuIeez5nJ-iegHaE7%26pid%3DApi&f=1"
                            contain
                            max-width="400"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                          <h4 class="text-center pt-2">2 photo(s)</h4>
                        </div>
                        <div>
                          <h3>Milking Devon Breeding Stock for Sale</h3>
                          <p>
                            American Milking Devon Bulls heifers and cows. All
                            are registered purebreds. our animals are well
                            socialized, and raised only on grass , hay and lots
                            of love. These beautiful animals are a rare tripple
                            purpose breed for milk, meat or oxen. A-1-A2 &
                            A-2A-A2 stock available. Please call or email for
                            more information or to arrange a herd vist.
                          </p>
                          <v-btn
                            link
                            to="/buy-view"
                            depressed
                            outlined
                            color="primary"
                          >
                            View Details
                          </v-btn>
                        </div>
                        <div>
                          <v-btn class="mb-6" depressed color="primary">
                            Contact Seller
                          </v-btn>

                          <v-icon large color="primary">mdi-map-marker</v-icon>
                          Gulu, Northern
                        </div>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-row class="pt-6">
                  <v-col cols="12">
                    <h4>Enter Listing Title</h4>
                    <v-text-field outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Livestock Type</h4>
                    <v-select outlined></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Breed</h4>
                    <v-select outlined></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Ad Type</h4>
                    <v-select outlined></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Location</h4>
                    <v-text-field outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <h4>Upload An Image</h4>
                    <v-file-input
                      outlined
                      prepend-icon="mdi-camera"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" md="12">
                    <h4>Detailed Description</h4>
                    <v-textarea outlined></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Mobile Phone Number</h4>
                    <v-text-field outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h4>Email Address</h4>
                    <v-text-field outlined></v-text-field>
                  </v-col>

                  <v-col class="d-flex justify-center" cols="12">
                    <v-btn depressed color="primary">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style></style>
